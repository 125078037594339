import { createSlice } from '@reduxjs/toolkit'

export const gameSlice = createSlice({
  name: 'game',
  initialState: {
    score: 0,
    move: 'rien',
    counter: 0
  },
  reducers: {
    // score en cas de victoire
    incrementScore: (state) => {
      state.score += 1
    },
    // score en cas de defaite
    decrementScore: (state) => {
      state.score += 0
    },
    // choix du joueur
    changeMove: (state, action) => {
      state.move = action.payload
    },
    // compteur du nombre de partie faites 
    counterGame: (state) => {
      state.counter += 1
    },
    // remise a zero du counter et score de partie faites 
    resetCounterGame: (state) => {
      state.counter = 0;
      state.score = 0;
    }
  },
})

// Action creators are generated for each case reducer function
export const { incrementScore, decrementScore, changeMove, counterGame ,resetCounterGame } = gameSlice.actions

export default gameSlice.reducer