import React from "react";
import "./score.css";
import { useSelector } from "react-redux";

export function Score() {
  const score = useSelector((state) => state.game.score);
  // console.log(score + ' score');
  return (
    <div className="score-container">
      <h2>score</h2>
      <p className="score">{score}</p>
    </div>
  );
}
