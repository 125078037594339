import React from "react";
import { useEffect, useRef } from "react";
import "./jeuPage.css";
import Coin from "../../components/coin/coin";
import { useSelector, useDispatch } from "react-redux";
import { decrementScore, incrementScore } from "../../features/game/gameSlice";
import { Link } from "react-router-dom";
import { Header } from "../../components/header/header";

function JeuPage() {
  const dispatch = useDispatch();
// tableaux des choix de l'ordi
  const moves = ["rock", "paper", "scissors"];
  // choix random 
  const cpLuck = Math.floor(Math.random() * moves.length);
  // choix random dans le tableaux
  const ordi = moves[cpLuck];

  const player = useSelector((state) => state.game.move);

  // pour afficher un message de resultat
  let resultat = "";

  // condition pour la gestion de la victoire, defaite, egalite
  if (
    (player === "rock" && ordi === "paper") ||
    (player === "paper" && ordi === "scissors") ||
    (player === "scissors" && ordi === "rock")
  ) {
    resultat = "Perdu";
    dispatch(decrementScore());
  } else if (
    (player === "rock" && ordi === "scissors") ||
    (player === "paper" && ordi === "rock") ||
    (player === "scissors" && ordi === "paper")
  ) {
    resultat = "Gagné";
    dispatch(incrementScore());
  } else if (player === ordi) {
    resultat = "Égalité";
  }

  const counter = useSelector((state) => state.game.counter);
  // console.log(counter);

  // permet de selection une balise
  const nextGame = useRef(null);
  const endGame = useRef(null);
  // console.log(nextGame);
  // console.log(endGame);

  // toggle de class pour l'apparition du btn de fin de jeu
  useEffect(() => {
    if (counter >= 10) {
      nextGame.current.classList.remove("back-button");
      nextGame.current.classList.add("back-buttonDisparition");
      endGame.current.classList.remove("end-button");
      endGame.current.classList.add("end-buttonApparition");
    } else {
      console.log("tu n'est pas encore arriver a la fin ;)");
    }
  });

  return (
    <div className="jeu-page">
      <Header className="header-jeupage"></Header>
      <section className="apercus-container">
        <p className="apercus"> joueur : {player} </p>
        <p className="apercus"> resultat : {resultat} </p>
        <p className="apercus"> ordinateur : {ordi} </p>
      </section>

      <div className="pieces">
        <Coin type={player}></Coin>
        <Coin type={ordi}></Coin>
      </div>

      <Link ref={nextGame} className="back-button" to="/">
        Prochaine manche
      </Link>

      <Link ref={endGame} className="end-button" to="/end">
        Fin de la Partie
      </Link>
    </div>
  );
}

export default JeuPage;
