import React from "react";
import "./coin.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Rock } from "../../assets/icon-rock.svg";
import { ReactComponent as Paper } from "../../assets/icon-paper.svg";
import { ReactComponent as Scissors } from "../../assets/icon-scissors.svg";
import { useDispatch } from "react-redux";
import { changeMove, counterGame } from "../../features/game/gameSlice";

function Coin({ type }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const choseMove = (e) => {
    e.preventDefault();
    // console.log(type);
    // choix du joueur
    dispatch(changeMove(type));
    // gestion du counter de manche
    dispatch(counterGame());
    //changement de page
    navigate("/jeu");
  };

  return (
    <div className={`coin-container ${type}`} onClick={choseMove}>
      {(() => {
        switch (type) {
          case "paper":
            return <Paper className="paper" />;
            break;
          case "scissors":
            return <Scissors className="scissors" />;
            break;
          case "rock":
            return <Rock className="rock" />;
            break;
          default:
            return "ca marche pas";
        }
      })()}
    </div>
  );
}

export default Coin;
