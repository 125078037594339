import React from "react";
import "./endgamepage.css";
import { Header } from "../../components/header/header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetCounterGame } from "../../features/game/gameSlice";

function EndGamePage() {
  // function permettant de remttre a zero le counter et score au click
  const dispatch = useDispatch();
  const resetCounter = () => {
    dispatch(resetCounterGame());
  };
  // console.log(resetCounter);

  // condition qui selon le score affiche une phrase differentes
  let gameOver = "";
  const score = useSelector((state) => state.game.score);
  if (score <= 2) {
    gameOver = "Tu as bien joué, mais tu peux surement faire mieux.";
  } else if (score >= 2) {
    gameOver = "C'est un score honorable.";
  } else if (score >= 8) {
    gameOver = "Bravo pour ton score, tu maîtrises vraiment le jeu.";
  } else if (score == 9) {
    gameOver = "Bravo à deux doigts du parcours parfait !";
  } else if (score == 10) {
    gameOver =
      "Félicitations pour ton 10/10 ! L'ordinateur n'est pas prêt à s'en remettre !";
  }

  return (
    <div className="endgame-container">
      <Header></Header>

      <p className="game-over">{gameOver}</p>

      <Link onClick={resetCounter} className="newgame-button" to="/">
        Nouvelle Partie
      </Link>
    </div>
  );
}

export default EndGamePage;
