import React from "react";
import "./header.css";
import { Score } from "../score/score";
import { ReactComponent as Logo } from "../../assets/logo.svg";

export function Header() {
  return (
    <header className="header-container">
      <h1>Jan Ken Pon</h1>
      <Logo className="logo"></Logo>
      <Score></Score>
    </header>
  );
}
