import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import HomePage from './pages/HomePage/homepage';
import JeuPage from './pages/jeu/jeuPage';
import EndGamePage from './pages/endGamePage/endgamepage';
import store from './store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/jeu" element={<JeuPage />} />
        <Route path="/end" element={<EndGamePage />} />
      </Routes>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

