import React from "react";
import "./homepage.css";
import Coin from "../../components/coin/coin";
import { ReactComponent as Triangle } from "../../assets/bg-triangle.svg";
import { Header } from "../../components/header/header";
import { useSelector } from "react-redux";

// A la fin des 10 manches faire basculer sur une autre page
// avec un message de resumer de la partie
// un boutton pour revenir a la premiere page avec un remise a zero du counter et score

function HomePage() {
  const moves = [
    {
      name: "rock",
    },
    {
      name: "paper",
    },
    {
      name: "scissors",
    },
  ];

  const counter = useSelector((state) => state.game.counter);
  // console.log(counter + ' counter');
  // const score = useSelector((state) => state.game.score)
  // const move = useSelector((state) => state.game.move)

  return (
    <div className="game-area">
      <Header></Header>
      <p className="manches">Combien arriverez-vous à faire en 10 manches ?</p>

      <div className="selection">
        {moves.map(({ name }, index) => (
          <Coin key={index} type={name}></Coin>
        ))}

        <Triangle className="triangle"></Triangle>
      </div>
      <p className="manches"> Vous êtes a la manche {counter}/10 </p>
    </div>
  );
}

export default HomePage;
